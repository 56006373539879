.input-auth {
  width: 45px;
  height: 45px;
  padding: 0;
  font-size: 24px;
  text-align: center;
  text-transform: uppercase;
  color: #494949;
  font-family: Poppins;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  background: #fff;
  background-clip: padding-box;
}

.input-auth:focus {
  appearance: none;
  outline: 0;
  box-shadow: 0 0 0 3px rgb(131 192 253 / 50%);
}

.container-auth {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap; /* Ensures the items won't wrap */
}

/* Adjustments for small screens */
@media screen and (max-width: 600px) {
  .input-auth {
    width: calc(100% / 6 - 8px); /* Adjusted width for smaller screens */
    margin-right: 0;
  }
  .input-auth:focus {
    box-shadow: 0 0 0 3px rgb(131 192 253 / 50%) inset; /* Only apply box shadow on left and right */
  }
}
