.imageContainer {
  width: 170.5px;
  height: 164px;
  border-radius: 15px;
  filter: brightness(75%);
  object-fit: cover;
  transition: transform 0.3s ease, filter 0.3s ease;
  cursor:pointer;
}

.imageContainer:hover {
  transform: scale(1.10);
  filter: brightness(100%);
}

.image {
  width: 100%;
  height: 100%;
  border-radius: inherit;
  object-fit: inherit;
}
