.ant-tabs-tabpane-active {
  height: 100% !important;
}

.ant-tabs-content {
  height: 100% !important;
}

#rc-tabs-3-panel-1 {
  height: 100% !important;
}