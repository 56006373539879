h2 {
  color: black;
}
.my-title-class {
  order: 0; /* Set the order to change the position of the title */
}

.my-image-class {
  order: 1; /* Set the order to change the position of the image */
}

.my-container-class {
  order: 2; /* Set the order to change the position of the image */
}

.my-actions-class {
  order: 3; /* Set the order to change the position of the image */
}
.ant-btn-primary:disabled {
  color: white !important;
  background-color: #d9d9d9 !important;
}
.small-gap-table td {
  padding: 8px 16px !important;
}
.ant-picker-dropdown .ant-picker-panel-container .ant-picker-panels {
  display: inline-flex;
  animation: none;
  flex-wrap: nowrap;
  direction: ltr;
  @media (max-width: 768px) {
    display: inline-grid;
  }
}
.ant-drawer-close {
  position: absolute;
  top: 20px;
  right: 0;
}
.ant-drawer-body {
  padding: 8px 16px !important;
}
@media (max-width: 768px) {
  .ant-drawer-body {
    padding: 8px !important;
  }
}

.ant-drawer-header {
  padding: 8px 16px !important;
}
@media (max-width: 768px) {
  .ant-drawer-header {
    padding: 8px !important;
  }
}
.custom-modal-initial-tour .ant-modal-content {
  padding: 0;
}

ul {
  list-style-position: inside;
}

ul ul {
  list-style-type: circle;
  margin-left: 20px;
}

ul ul ul {
  list-style-type: square;
  margin-left: 20px;
}

.ant-tabs-ink-bar {
  height: 10px !important;
  border-radius: 10px 10px 0px 0px;
}

@media screen and (max-width: 768px) {
  .ant-tabs-ink-bar {
    height: 6px !important;
  }
  .ant-tabs-tab-btn {
    padding: 6px 0px 0px 0px !important;
  }
}

.ant-tabs-nav::before {
  border-bottom: none; /* Remove the default bottom border */
}

.ant-tabs-tab {
  padding: 0;
  margin: 0;
}

.ant-tabs-tab + .ant-tabs-tab {
  margin-left: 32px; /* Adjust the space between tabs if needed */
}

.ant-tabs-tab-btn {
  padding: 12px 0px 0px 0px; /* Adjust padding to your preference */
}
