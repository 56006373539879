.modal-qr-code .ant-modal-content {
  background-color: transparent !important;
  box-shadow: none !important;
}

.modal-qr-code .ant-modal-body {
  background-color: transparent !important;
}

.modal-qr-code .ant-modal-header {
  background-color: transparent !important;
}