.extra-padding-top .slick-track{
    display: flex !important;
    padding-top:32px !important;
    padding-bottom:16px ;
}
.extra-padding-top .slick-slider {
    margin-inline: -30px; 
  }

.slick-slide {
    >div { 
      margin-inline: 15px; 
    }
  }