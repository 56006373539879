.right {
  transform: translateX(300px);
}
.left {
  transform: translateX(0px);
}
.leftMobile {
  transform: translateX(-57px);
}
.rightMobile {
  transform: translateX(0px);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  transition:1s;
  transition: background-color 0.3s ease;
  z-index: 1000; /* Adjust the z-index to ensure it's above other elements */
  display: none;
}

.overlay.active {
	background-color: rgba(0, 0, 0, 0.4);
  display: block;
}
