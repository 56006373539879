:root {
  --primary-color: #7743b9;
  --button-hover-color: #ad73eb;
  --button-active-color: #452392;
}
.btn-close {
  padding: 8px;
  border-radius: 10px;
  background: var(--primary-color);
  width: fit-content;
  color: white;
  font-size: 14px;
  align-self: center;
}
.btn-close:hover {
  background: var(--button-hover-color);
}
.btn-close:active {
  background: var(--button-active-color);
}
.modal-with-background .ant-modal-content {
  background-color: transparent !important;
  box-shadow: none;
}

.modal-with-background {
  background-size: 40%, 25%;
  background-repeat: no-repeat, no-repeat;
  background-position: left bottom, right top;
  background-color: white;
}
.modal-with-background .ant-modal-content {
  padding: 40px;
}

@media (max-width: 768px) {
  .modal-with-background .ant-modal-content {
    padding: 16px !important;
  }
}
.custom-table .ant-table-wrapper {
  border-radius: 0px !important;
  border: 1px solid #c4c4c4 !important;
  box-shadow: none !important;
}
