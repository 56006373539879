.center-carousel .slick-center {
  transform: scale(1.25) !important;
  transition: transform 0.3s ease;
}

.center-carousel .slick-slide > div {
  margin-inline: 40px;
}

.center-carousel .slick-slide > div > div{
  height:100%;
}

@media (max-width: 768px) {
  .center-carousel .slick-slide > div {
    margin-inline: 20px;
  }
}