

.notifications-item {
  display: flex;
  padding: 6px 9px;
  margin-bottom: 0px;
  cursor: pointer;
}
.notifications-item:hover {
  border-radius: 20px;
  background: rgba(237, 129, 15, 0.15);
}
.notifications-item img {
  display: block;
  margin-right: 9px;
  border-radius: 50%;
  margin-top: 2px;
}
.notifications-item .text h4 {
  color: #777;
  font-size: 16px;
  margin-top: 3px;
}
