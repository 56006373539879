.student-keys-container {
  display: none;
}

@media print {
  .student-keys-container {
    display: flex !important;
    flex-direction: column;
  }
  .page-break {
    page-break-after: always;
  }
  .student-key-page:last-child {
    page-break-after: auto;
  }
}
