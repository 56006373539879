.student-keys-notes-container {
  display: none;
}

@media print {
  .student-keys-notes-container {
    display: block !important;
    /* padding: 20px 24px;  */
  }
  .student-key-item-wrapper.page-break {
    page-break-after: always;
  }
}

