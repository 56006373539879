.star {
    align-items: center;
    display: flex;
    height: inherit; /* Remove $size variable */
    justify-content: center;
    margin: 0 auto;
    position: relative;
    transform: rotate(45deg);
    width: inherit; /* Remove $size variable */
    color:white;
    font-weight:700;
    font-family: Poppins;
  }
  
  .star::before,
  .star::after {
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }
  
  .star::before {
    background-color: inherit; /* Remove $backgroundColor variable */
    transform: rotate(30deg);
  }
  
  .star::after {
    background-color: inherit; /* Remove $backgroundColor variable */
    transform: rotate(60deg);
  }
  
  .star p {
    transform: rotate(-45deg);
  }
  
  .star-content {
    position: relative;
    z-index: 1;
  }
  