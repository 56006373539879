:root {
  --primary-color: #7743B9;
  --button-hover-color: #AD73EB;
  --button-active-color: #452392;

}
.modal-with-background .ant-modal-content{
  background-color: transparent !important;
  box-shadow: none;
}

.modal-with-background {
  background-size: 40%, 25%;
  background-repeat: no-repeat, no-repeat;
  background-position: left bottom, right top;
  background-color: white;
}
.modal-with-background .ant-modal-content {
  padding: 40px;
}