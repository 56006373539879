.timeline-card-row:hover {
  background-color: #EFE8F6;
}

.grave {
  background-color:  #B943862E;
  border-radius: 4px;
  color: #B94386 ;
  height: 28px;
  padding: 4px, 8px, 4px, 8px;
  width: 80px;
}

#grave {
  background-color: #B94386;
  border-radius: 100%;
  height: 8px;
  width: 8px;
}

.moderada {
  background-color:#B976432E;
  border-radius: 4px;
  color: #B97643 ;
  height: 29px;
  padding: 4px, 8px, 4px, 8px;
  width: 114px;
}

#moderada {
  background-color: #B97643;
  border-radius: 100%;
  height: 8px;
  width: 8px;
}

.leve {
  background-color:#86B9432E;
  border-radius: 4px;
  color: #86B943 ;
  height: 29px;
  padding: 4px, 8px, 4px, 8px;
  width: 64px;
}

#leve {
  background-color: #86B943;
  border-radius: 100%;
  height: 8px;
  width: 8px;
}

.nao-manifesto {
  background-color: #7743B92E;

  border-radius: 4px;
  color: #7743B9 ;
  height: 29px;
  padding: 4px, 8px, 4px, 8px;
  width: 146px;
}

#nao-manifesto {
  background-color: #7743B9;
  border-radius: 100%;
  height: 8px;
  width: 8px;
}