.menuItem {
  color: white;
  font-size: 16px;
}

.menuItem:hover {
  border-left: 5px solid #ED810F;
  border-radius: 4px;
}

.menuItem:active {
  margin-left: 5px;
  border-left: none;
  background-color: white;
  color: #562FA1;
}

.floatButton {
  color: #7743B9;
  background-color: #7743B9;
  border: 2px solid #562FA1;
  width: 50px;
  height: 50px;
  transition: transform 0.3s ease;
}

.rotate {
  transform: rotate(45deg);
}