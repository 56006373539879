.title-search {
  align-items: center;
  border-bottom: 30px;
  display: flex;
  justify-content: flex-end;
}

.students-content {
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: space-evenly;
  width: 90%;
}

.students-page {
  display: flex;
  height: auto;
  justify-content: center;
  width: 100%;
}