.left {
  transform: translateX(-394px);
}
.leftMobile {
  transform: translateX(-88vw);
}
.right {
  transform: translateX(0px);
}

