.imageContainer {
  position: relative;
  z-index: 0;
  border-radius: 15px;
  filter: brightness(75%);
  object-fit: cover;
  transition: transform 0.3s ease, filter 0.3s ease;
  cursor: pointer;
}

.imageContainer:hover {
  transform: scale(1.1);
  filter: brightness(100%);
  z-index: 49;
}
.imageContainer:hover .gameInfo {
  opacity: 1;
  visibility: visible;
  z-index: 51;
}

.image {
  z-index: 2;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  object-fit: inherit;
  aspect-ratio: 16/9;
}

.gameInfo {
  z-index: 0;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  border-radius: 0px 0px 5px 5px;
  background: white;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  padding: 8px;
  position: relative;
}
